import GigyaService from '../gigyaService';
import normalizeUser from './user-normalizer';

let authService = null;

export const initialState = () => ({
  // user is logged in if we have the information from gigya
  user: {
    jwt: null,
    uid: null,
    email: null,
  },
  authError: null,
});

export const getters = {
  authHeaders: ({ user = {} }) => {
    // const cookiesObject = parseCookies();
    // console.log(
    //   '🚀 ~ fetchConsumerFromApi ~ cookiesObject:',
    //   cookiesObject
    // );
    // const gig_jwt = cookiesObject['gig_jwt'];
    // console.log('🚀 ~ fetchConsumerFromApi ~ gig_jwt:', gig_jwt);

    const gig_jwt = 'guttentag1234';

    return {
      headers: {
        'x-login-token': gig_jwt,
      },
    };
  },
  userEmail: ({ user = {} }) => user?.email,
  isUserAuthenticated: ({ user = {} }) =>
    // @TODO: Check for token expiration date
    user.jwt !== null && user.uid !== null,
  isAuthProcessFinished: ({ authError, user }) =>
    Boolean(authError || user.jwt),
  authError: ({ authError }) => authError,
  userId: ({ user = {} }) => user?.uid,
};

export const actions = {
  async initAuth({ commit, dispatch }) {
    const curryCommit = (message) => (payload) => commit(message, payload);
    if (authService) {
      authService.clearIntervalsAndTimeouts();
      authService.fetchJWT(curryCommit('SET_JWT_TOKEN'));
      authService.fetchAccountInfo((user) =>
        commit('SET_USER', normalizeUser(user))
      );
      return;
    }
    authService = new GigyaService({
      jwtAction: curryCommit('SET_JWT_TOKEN'),
      userAction: (user) => commit('SET_USER', normalizeUser(user)),
      errorAction: (error) => commit('SET_AUTH_ERROR', error),
      logoutAction: () => {
        console.log('XXXX logoutAction XXXX');
        console.info('XXXX logoutAction XXXX');
        dispatch('logout');
      },
    });
  },
  stopAuth() {
    if (authService) {
      authService.clearIntervalsAndTimeouts();
    }
  },
  refreshToken({ commit }) {
    if (authService) {
      return new Promise((resolve) => {
        authService.fetchJWT((jwt) => {
          commit('SET_JWT_TOKEN', jwt);
          resolve(jwt);
        });
      });
    } else {
      throw new Error('GigyaService is not running...');
    }
  },
  async refreshHeaders({ dispatch, getters }) {
    try {
      await dispatch('refreshToken');
      return await getters.authHeaders;
    } catch (error) {
      throw new Error('Couldnt refresh the JWT, error is', error);
    }
  },
  async logout({ commit }) {
    if (authService) {
      const loggedOut = await authService.logout();
      console.log('XXX loggedOut XXX ', loggedOut);
      if (loggedOut) {
        // unset all user/customer related data
        commit('SET_JWT_TOKEN', null);
        commit('SET_USER', { uid: null, email: null });
        commit('consumerModule/LOGOUT', null, { root: true });
        window.location = process.env.VUE_APP_DCE_LOGIN_URL;
      }
    } else {
      throw new Error('GigyaService is not running...');
    }
  },
};

export const mutations = {
  SET_JWT_TOKEN(state, jwt) {
    state.user.jwt = jwt;
    // TODO: Restore this line
    // console.debug('SET_JWT_TOKEN', state.user.jwt);
  },
  SET_USER(state, { uid, email }) {
    state.user.uid = uid;
    state.user.email = email;
    console.debug('SET_USER', state.user);
  },
  SET_AUTH_ERROR(state, error) {
    state.authError = error;
    console.debug('SET_AUTH_ERROR', state.authError);
  },
  SET_AUTH_SERVICE_LOADED(state) {
    state.isAuthServiceLoaded = true;
    console.debug('SET_AUTH_SERVICE_LOADED', true);
  },
};

const authModule = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};

export default authModule;
